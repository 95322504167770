import { connect } from 'react-redux';
import CreateIncident from '../../Components/CreateIncident';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { CREATE_INCIDENT } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[CREATE_INCIDENT],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(CreateIncident, {
  name: CREATE_INCIDENT,
  defaultUrl: Config.reactApp.CREATE_INCIDENT_URL
}));
