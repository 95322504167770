import { connect } from 'react-redux';
import IncidentList from '../../Components/IncidentList';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { INCIDENT_LIST } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[INCIDENT_LIST],
  ...ownProps
});

export default connect(mapStateToProps, {})(withIframeHistory(IncidentList, {
  name: INCIDENT_LIST,
  defaultUrl: Config.reactApp.INCIDENT_LIST_URL
}));
