import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Home from './Components/Home';
import CreateIncident from './Containers/CreateIncident';
import IncientList from './Containers/IncidentList';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/',
      exact: true,
      component: Home
    },
    {
      path: '/create-incident',
      exact: true,
      component: CreateIncident
    },
    {
      path: '/incident-list',
      exact: true,
      component: IncientList
    },
    {
      path: '/id-homepage',
      exact: true,
      component: IDHomepage
    }
  ]
}];

Root.propTypes = {
  route: PropTypes.object.isRequired
};
