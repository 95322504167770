import { Ability, AbilityBuilder } from '@casl/ability';
import { MANAGER } from '../../constants/jobRoleCategory';
import { ROLE_SITE_ADMIN, ROLE_INCIDENT_ACCESS, ROLE_CONTENT_ADMIN } from '../../constants/roles';
import * as screens from '../../constants/screens';

function grantSiteAdminAndManagerPermissions(can) {
  can('view', 'ManagerMenu');
  can('view', screens.INCIDENT_LIST);
}

function grantContentAdminPermission(can) {
  can('view', 'ManagerMenu');
  can('view', screens.CREATE_INCIDENT);
}

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }
  // this one is required
  // eslint-disable-next-line no-underscore-dangle
  return item.__type;
}

const ability = new Ability([], { subjectName });

export function updateAbility(currentAbility, decodedIdToken) {
  console.log('🚀 ~ file: ability.js:29 ~ updateAbility ~ decodedIdToken:', decodedIdToken);
  const { can, rules } = new AbilityBuilder();
  can('view', 'WelcomePage');

  if (decodedIdToken && decodedIdToken['cognito:groups']
  && decodedIdToken['cognito:groups'].includes(ROLE_INCIDENT_ACCESS)
  && (decodedIdToken.job_role_category === MANAGER || decodedIdToken['cognito:groups'].includes(ROLE_SITE_ADMIN))) {
    grantSiteAdminAndManagerPermissions(can);
  }

  if (decodedIdToken && decodedIdToken['cognito:groups']
  && decodedIdToken['cognito:groups'].includes(ROLE_CONTENT_ADMIN)) {
    grantContentAdminPermission(can);
  }

  currentAbility.update(rules);
}

export default ability;
