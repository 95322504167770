import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe, getParsedIrameUrl } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';
import { INCIDENT_LIST } from '../../constants/screens';

export default function IncidentListComponent({
  currentUrl,
  currentLanguage,
  onUnMount,
  onMount,
}) {
  const parsedIframeUrl = useMemo(
    () => getParsedIrameUrl(currentUrl, currentLanguage),
    [currentLanguage]
  );

  useEffect(() => {
    onMount(
      getParsedIrameUrl(Config.reactApp.INCIDENT_LIST_URL, currentLanguage)
    );

    return () => {
      onUnMount();
    };
  }, []);

  return (
    <Can I="view" a={INCIDENT_LIST}>
      <LoadingIframe src={parsedIframeUrl} />
    </Can>
  );
}

IncidentListComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  onUnMount: PropTypes.func,
};

IncidentListComponent.defaultProps = {
  onUnMount: () => {},
  onMount: () => {},
};
