import { languageOptions } from '../translations/languageOptions';

export function localeMapper(locale) {
  const map = {
    pt: 'pt-BR',
    zh: 'zh-Hans',
  };
  return map[locale] || locale;
}
export const localeOptions = languageOptions.map(
  (l) => ({ text: l.translationKey, value: localeMapper(l.key) })
);
export function localeTextByValue(value) {
  const locale = localeOptions.find((obj) => obj.value === value);
  return locale ? locale.text : '';
}
